import React, {FC, useCallback, useState} from "react";
import {Button, Checkbox, Divider, Form, Grid, Header, Segment} from "semantic-ui-react";
import {DateInput} from "semantic-ui-calendar-react";
import {endOfDay, format, parse, startOfDay} from "date-fns";
import {RouteComponentProps} from "react-router";
import {withRouter} from "react-router-dom";
interface CreateCFProps extends RouteComponentProps {}

export const CreateCCF: FC<CreateCFProps> = withRouter((props: CreateCFProps) => {
    const [created, setCreated] = useState<boolean>(false);
    const [name, setName] = useState<string>("");
    const [contactPersonName, setContactPersonName] = useState<string>("");
    const [department, setDepartment] = useState<string>("");

    const navigate = useCallback((path) => {
        props.history.push(path);
    }, []);

    const [filterDateFrom, setFilterDateFrom] = useState(
        startOfDay(Date.parse("2023-01-01"))
    );
    const [filterDateTo, setFilterDateTo] = useState(endOfDay(Date.parse("2023-12-31")));

    return(
        <div>
            <Header as="h1">{created ? "Anpassung" : "Anlage"} neuer CCF</Header>
            <Divider/>
            {/*<Segment>*/}
                <Grid>
                    <Grid.Row columns={6} verticalAlign={"middle"}>
                        <Grid.Column>
                            <Form>
                                <Form.Field width={16}>
                                    <label>Name</label>
                                    <input value={name} onChange={event => setName(event.target.value)}/>
                                </Form.Field>
                            </Form>
                        </Grid.Column>
                        <Grid.Column>
                            <Form>
                                <Form.Field width={16}>
                                    <label>Ansprechpartner</label>
                                    <input value={contactPersonName} onChange={event => setContactPersonName(event.target.value)}/>
                                </Form.Field>
                            </Form>
                        </Grid.Column>
                        <Grid.Column>
                            <Form>
                                <Form.Field width={16}>
                                    <label>Abteilung</label>
                                    <input value={department} onChange={event => setDepartment(event.target.value)}/>
                                </Form.Field>
                            </Form>
                        </Grid.Column>
                        <Grid.Column>
                            <Form>
                                <Form.Field width={16}>
                                    <label>Gültig ab</label>
                                    <DateInput
                                        name="datesRangeFrom"
                                        placeholder="Von"
                                        value={format(filterDateFrom, "yyyy-MM-dd")}
                                        iconPosition="left"
                                        dateFormat="YYYY-MM-DD"
                                        onChange={(event: any, { value }: any) => {
                                            setFilterDateFrom(
                                                startOfDay(parse(value, "yyyy-MM-dd", new Date()))
                                            );
                                        }}
                                    />
                                </Form.Field>
                            </Form>
                        </Grid.Column>
                        <Grid.Column>
                            <Form>
                                <Form.Field width={16}>
                                    <label>Gültig bis</label>
                                    <DateInput
                                        name="datesRangeTo"
                                        placeholder="Von"
                                        value={format(filterDateTo, "yyyy-MM-dd")}
                                        iconPosition="left"
                                        dateFormat="YYYY-MM-DD"
                                        onChange={(event: any, { value }: any) => {
                                            setFilterDateTo(
                                                endOfDay(parse(value, "yyyy-MM-dd", new Date()))
                                            );
                                        }}
                                    />
                                </Form.Field>
                            </Form>
                        </Grid.Column>
                        <Grid.Column>
                            <Button floated={"right"} primary onClick={() => setCreated(true)} disabled={false}>{created ? "Speichern" : "Anlegen"}</Button>
                            <Button floated={"right"} secondary onClick={props.history.goBack}>Zurück</Button>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            {/*</Segment>*/}
            <Grid columns={2}>
                <Grid.Row stretched>
                    <Grid.Column>
                        <Segment>
                            <Header as="h3">Scope 1 - Direkte Emissionen</Header>
                            <Divider/>
                            <Grid columns={2} verticalAlign={"middle"}>
                                <Grid.Row>
                                    <Grid.Column>
                                        <Checkbox disabled={false} defaultChecked label={"Stationäre Verbrennung"}/>
                                    </Grid.Column>
                                    <Grid.Column>
                                        <Button disabled={!created} floated={"right"}>Editieren</Button>
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row>
                                    <Grid.Column>
                                        <Checkbox disabled={false} defaultChecked label={"Mobile Verbrennung"}/>
                                    </Grid.Column>
                                    <Grid.Column>
                                        <Button disabled={!created} onClick={navigate.bind(null, "/maintenance/editFactor")} floated={"right"}>Editieren</Button>
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row>
                                    <Grid.Column>
                                        <Checkbox disabled={false} label={"Kühlmittel"}/>
                                    </Grid.Column>
                                    <Grid.Column>
                                        <Button disabled floated={"right"}>Editieren</Button>
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row>
                                    <Grid.Column>
                                        <Checkbox disabled={false} label={"Flüchtige Emissionen"}/>
                                    </Grid.Column>
                                    <Grid.Column>
                                        <Button disabled floated={"right"}>Editieren</Button>
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </Segment>
                        <Segment>
                            <Header as="h3">Scope 2 - Indirekte Emissionen aus eingekaufter Energie</Header>
                            <Divider/>
                            <Grid columns={2} verticalAlign={"middle"}>
                                <Grid.Row>
                                    <Grid.Column>
                                        <Checkbox disabled={false} defaultChecked label={"Strom"}/>
                                    </Grid.Column>
                                    <Grid.Column>
                                        <Button disabled={!created} floated={"right"}>Editieren</Button>
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row>
                                    <Grid.Column>
                                        <Checkbox disabled={false} label={"Dampf"}/>
                                    </Grid.Column>
                                    <Grid.Column>
                                        <Button disabled floated={"right"}>Editieren</Button>
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row>
                                    <Grid.Column>
                                        <Checkbox disabled={false} label={"Fernwärme"}/>
                                    </Grid.Column>
                                    <Grid.Column>
                                        <Button disabled floated={"right"}>Editieren</Button>
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </Segment>
                    </Grid.Column>
                    <Grid.Column>
                        <Segment>
                            <Header as="h3">Scope 3</Header>
                            <Divider/>
                            <Header as="h4">Vorgelagerte Emissionen</Header>
                            <Grid columns={2} verticalAlign={"middle"}>
                                <Grid.Row>
                                    <Grid.Column>
                                        <Checkbox disabled={false} label={"Gekaufte Waren und Dienstleistungen"}/>
                                    </Grid.Column>
                                    <Grid.Column>
                                        <Button disabled floated={"right"}>Editieren</Button>
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row>
                                    <Grid.Column>
                                        <Checkbox disabled={false} label={"Investitionsgüter"}/>
                                    </Grid.Column>
                                    <Grid.Column>
                                        <Button disabled floated={"right"}>Editieren</Button>
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row>
                                    <Grid.Column>
                                        <Checkbox disabled={false} label={"Brennstoff- und energiebezogene Aktivitäten"}/>
                                    </Grid.Column>
                                    <Grid.Column>
                                        <Button disabled floated={"right"}>Editieren</Button>
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row>
                                    <Grid.Column>
                                        <Checkbox disabled={false} defaultChecked label={"Vorgelagerter Transport und Vertrieb"}/>
                                    </Grid.Column>
                                    <Grid.Column>
                                        <Button disabled={!created} floated={"right"}>Editieren</Button>
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row>
                                    <Grid.Column>
                                        <Checkbox disabled={false} defaultChecked label={"Im Betrieb anfallende Abfälle"}/>
                                    </Grid.Column>
                                    <Grid.Column>
                                        <Button disabled={!created} floated={"right"}>Editieren</Button>
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row>
                                    <Grid.Column>
                                        <Checkbox disabled={false} defaultChecked label={"Geschäftsreisen"}/>
                                    </Grid.Column>
                                    <Grid.Column>
                                        <Button disabled={!created} floated={"right"}>Editieren</Button>
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row>
                                    <Grid.Column>
                                        <Checkbox disabled={false} defaultChecked label={"Pendeln von Arbeitnehmern"}/>
                                    </Grid.Column>
                                    <Grid.Column>
                                        <Button disabled={!created} floated={"right"}>Editieren</Button>
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row>
                                    <Grid.Column>
                                        <Checkbox disabled={false} label={"Vorgelagerte geleaste Vermögenswerte"}/>
                                    </Grid.Column>
                                    <Grid.Column>
                                        <Button disabled floated={"right"}>Editieren</Button>
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                            <Header as="h4">Nachgelagerte Emissionen</Header>
                            <Grid columns={2} verticalAlign={"middle"}>
                                <Grid.Row>
                                    <Grid.Column>
                                        <Checkbox disabled={false} defaultChecked label={"Nachgelagerter Transport und Vertrieb"}/>
                                    </Grid.Column>
                                    <Grid.Column>
                                        <Button disabled={!created} floated={"right"}>Editieren</Button>
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row>
                                    <Grid.Column>
                                        <Checkbox disabled={false} label={"Verarbeitung der verkauften Produkte"}/>
                                    </Grid.Column>
                                    <Grid.Column>
                                        <Button disabled floated={"right"}>Editieren</Button>
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row>
                                    <Grid.Column>
                                        <Checkbox disabled={false} label={"End-of-Life-Behandlung von verkauften Produkten"}/>
                                    </Grid.Column>
                                    <Grid.Column>
                                        <Button disabled floated={"right"}>Editieren</Button>
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row>
                                    <Grid.Column>
                                        <Checkbox disabled={false} label={"Nachgelagerte geleaste Vermögenswerte"}/>
                                    </Grid.Column>
                                    <Grid.Column>
                                        <Button disabled floated={"right"}>Editieren</Button>
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row>
                                    <Grid.Column>
                                        <Checkbox disabled={false} label={"Franchises"}/>
                                    </Grid.Column>
                                    <Grid.Column>
                                        <Button disabled floated={"right"}>Editieren</Button>
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row>
                                    <Grid.Column>
                                        <Checkbox disabled={false} label={"Investitionen"}/>
                                    </Grid.Column>
                                    <Grid.Column>
                                        <Button disabled floated={"right"}>Editieren</Button>
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </Segment>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </div>
    );
});