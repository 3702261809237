import * as React from 'react';
import './AppContainer.css';
import LoginContainer from "../LoginContainer/LoginContainer";
import ContentContainer from "../ContentContainer/ContentContainer";
import {default as authService} from "../../services/AuthService";
import {Switch, Route, Redirect} from 'react-router';
import LoadingContainer from "../LoadingContainer/LoadingContainer";
import i18n from "../../../../locale/i18n";
import AppFontSize from "../../../../fontSize";
import {updateAppInfo} from '../../services/AppInfo';

import {appInfo} from "../../services/AppInfo";
import RegistrationContainer from "../RegistrationContainer/RegistrationContainer";
import {ToastContainer} from "react-toastify";

type AppContainerState = {
    loading: boolean;
}

class AppContainer extends React.Component<{}, AppContainerState> {

    static instance: AppContainer;

    constructor(props: {}) {
        super(props);

        AppContainer.instance = this;

        this.state = {
            loading: true,
        };

    }

    async componentDidMount () { // TODO good error handling when one of the required init requests fail!?
        let startTime = +(new Date());
        try {
          // await updateAppInfo();
        } catch(e) {
          // do nothing for now
        }
        try {
            await authService.dummyRequest();
        } finally {
            let endTime = +(new Date());
            console.log(`Initialized in ${endTime - startTime} ms`);
            this.setState({loading: false});
        }
    }

    render() {
        return this.state.loading ? (
            <LoadingContainer showLogo={true}/>
            ) : (
            <>
                <ToastContainer></ToastContainer>
                <Switch>
                    {!authService.loggedIn &&<Route exact={true} path="/login" component={LoginContainer}/>}
                    {!authService.loggedIn && <Redirect path="/" to="/login"/>}
                    {<Route component={ContentContainer}/>}
                </Switch >
            </>
        );
    }
}

export default AppContainer;
