import axios, {AxiosError, AxiosInstance, AxiosPromise, AxiosResponse} from 'axios';
import ErrorBox from "../modules/App/components/ErrorBox/ErrorBox";

import i18n from "../locale/i18n";
import AuthService from "../modules/App/services/AuthService";
import {toast} from "react-toastify";

const translator = i18n.t.bind(i18n);

interface IParams{[key: string]: any};

class RestClient {

    private client: AxiosInstance;
    private requests: number = 0;

    public baseUrl = "./api/";


    constructor () {
        this.client = axios.create({
            baseURL: this.baseUrl
        });
        this.client.defaults.xsrfCookieName = 'XSRF-TOKEN';
        this.client.defaults.xsrfHeaderName = 'X-XSRF-TOKEN';
        this.injectRequestCounter();
    }

    public delete (path: string, params?: IParams) {
        return this.call(path, {method: 'delete', params});
    }

    public delete_withPayload(path: string, data: {},params?: IParams) {
        return this.call(path, {method: 'delete', data, params});
    }

    public get (path: string, params?: IParams) {
        return this.call(path, {method: 'get', params});
    }

    public patch (path: string, data: {}, params?: IParams) {
        return this.call(path, {method: 'patch', data, params});
    }

    public post (path: string, data?: {}, params?: IParams) {
        return this.call(path, {method: 'post', data, params});
    }

    public put (path: string, data: {}, params?: IParams) {
        return this.call(path, {method: 'put', data, params});
    }

    public setHeader (key: string, value: any) {
        this.client.defaults.headers[key] = value;
    }

    public requestsInProgress () {
        return this.requests > 0;
    }

    public waitForRequests (MAX_TRIES: number = 10) {
        return new Promise<void>((resolve, reject) => {
            let tries = 0;
            const check = () => {
                tries++;
                if (tries > MAX_TRIES) { reject(); return; }
                if (!this.requestsInProgress()) { resolve(); return; }
                setTimeout(check, 100);
            };
            check();
        });
    }

    private call (path: string, options: {}) {
        return this.unwrapPromise(this.client.request({
            url: path,
            ...options,
            paramsSerializer: (params) => {
                return Object.keys(params).map(key => `${key}=${encodeURIComponent(params[key])}`).join('&');
            },
        }));
    }

    private unwrapPromise (promise: AxiosPromise): Promise<AxiosResponse> {
        return new Promise((resolve, reject) => promise.then(resolve, reject));
    }

    private injectRequestCounter () {
        const handleError = (error: AxiosError) => {
            this.requests--;

            if(!error.response) {
                return Promise.reject(error);
            }

            //ErrorBox
            if (error.response.status === 401) {
                ErrorBox.show(translator("Error.ErrorOccured"), translator("Error.SessionExpired"));
            } else if (error.response.status === 500) {
                // ErrorBox.show(translator("Error.ErrorOccured"), translator("Error.InternalServerError"));
                toast.error('Funktion steht derzeit nicht zur Verfügung, bitte versuchen Sie es später erneut.', {
                    toastId: 'internal-server-error'
                });
            } else if (error.response.status === 404) {
                // TODO this should be handled by the caller probably to handle...?
                ErrorBox.show(translator("Error.ErrorOccured"), translator("Error.NotFound"));
            } else {
                ErrorBox.show(translator("Error.ErrorOccured"), translator("Error.UnknownError"));
                console.log(error);
            }

            return Promise.reject(error);
        };
        this.client.interceptors.request.use((request) => {
            this.requests++;
            return request;
        }, handleError.bind(this));
        this.client.interceptors.response.use((response) => {
            this.requests--;
            return response;
        }, handleError.bind(this));
    }
}
const RestClientInstance = new RestClient();
export default RestClientInstance;

//@ts-ignore
window.REST_CLIENT = RestClientInstance;
