import React, {useEffect, useState} from "react";
import {Card, CardBody, CardHeader, Typography,} from "@material-tailwind/react";
import Chart from "react-apexcharts";
import RestClient from "../../../lib/RestClient";

const initialPieChartConfig = {
    type: "pie",
    series: [1],
    options: {
        chart: {
            toolbar: {
                show: false,
            },
        },
        labels: ["Kein Verbrauch"],
        title: {
            show: "",
        },
        dataLabels: {
            enabled: true,
            style: {
                colors: ["#ffffff"], // Set the color to white for pie labels
            },
        },
        colors: ["#001f78", "#00b5db", "#f03d14", "#fa6e0a", "#ffbf45"],
    },
};


const initialBarChartConfig = {
    type: "bar",
    series: [
        {
            name: "Anzahl",
            data: [0, 0],
        },
    ],
    options: {
        chart: {
            toolbar: {
                show: false,
            },
        },
        title: {
            show: "",
        },
        dataLabels: {
            enabled: false,
        },
        colors: ["#001f78", "#00b5db", "#f03d14", "#fa6e0a", "#ffbf45"],
        plotOptions: {
            bar: {
                columnWidth: "40%",
                borderRadius: 2,
            },
        },
        xaxis: {
            axisTicks: {
                show: false,
            },
            axisBorder: {
                show: false,
            },
            labels: {
                style: {
                    colors: "#616161",
                    fontSize: "12px",
                    fontFamily: "inherit",
                    fontWeight: 400,
                },
            },
            categories: [
                "Datenlücke",
                "Verbindungsverlust 24 Stunden",
                "Andere Fehler"
            ],
        },
        yaxis: {
            labels: {
                style: {
                    colors: "#616161",
                    fontSize: "12px",
                    fontFamily: "inherit",
                    fontWeight: 400,
                },
            },
        },
        grid: {
            show: true,
            borderColor: "#dddddd",
            strokeDashArray: 5,
            xaxis: {
                lines: {
                    show: true,
                },
            },
            padding: {
                top: 5,
                right: 20,
            },
        },
        fill: {
            opacity: 0.8,
        },
        tooltip: {
            theme: "dark",
        },
    },
};

export default function ErrorPie() {
    const [locations, setLocations] = useState([
        {value: "DE002068", label: "DE002068"},
        {value: "DE032065", label: "DE032065"}
    ]);
    const [selectedLocation, setSelectedLocation] = useState("DE032065")

    const [selectedFromDate, setSelectedFromDate] = useState("2023-01-01")
    const [selectedToDate, setSelectedToDate] = useState("2024-05-01")

    const [pieChartConfig, setPieChartConfig] = useState(initialPieChartConfig)
    const [barChartConfig, setBarChartConfig] = useState(initialBarChartConfig)

    const [consumptionData, setConsumptionData] = useState([])
    const [errors, setErrors] = useState([])

    const [totalConsumption, setTotalConsumption] = useState(0);

    function handleLocationChange(event) {
        setSelectedLocation(event.target.value)
    }

    function fetchPieChartData() {
        RestClient.get("temp/consumptionSummary/" + selectedLocation + "?dateFrom=" + selectedFromDate + "&dateTo=" + selectedToDate)
            .then(response => {
                    const data = Object.entries(response.data).map(([type, consumption]) => ({type, consumption}))
                    data.sort((a, b) => b.consumption - a.consumption)
                if (data.length === 0) {
                    setTotalConsumption(0)
                    setPieChartConfig(initialPieChartConfig)
                } else {
                    setConsumptionData(data)
                }
                }
            )
    }

    function fetchBarChartData() {
        function getSubject(error) {
            if (!error) {
                return "";
            }
            if (error === "data-gap") {
                return "Datenlücke";
            }
            if (error === "no-values-24h") {
                return "Keine Daten in den letzten 24 Stunden";
            }
            if (error === "average-deviation") {
                return "Abweichung vom Mittelwert";
            }
            if (error === "interpretation-failed") {
                return "Interpretation fehlgeschlagen";
            }
            return "Anderer Fehler";
        }

        RestClient.get("temp/storeErrorsBar/" + selectedLocation + "?dateFrom=" + selectedFromDate + "&dateTo=" + selectedToDate)
            .then(response => {
                    const data = Object.entries(response.data).map(([type, count]) => ({
                        type: getSubject(type)
                        , count
                    }))
                    if (data.length === 0) {
                        setBarChartConfig(initialBarChartConfig)
                    } else {
                        setErrors(data)
                    }
                }
            )
    }

    useEffect(() => {
        setPieChartConfig({
            ...pieChartConfig,
            options: {
                ...pieChartConfig.options,
                labels: consumptionData.map((data) => data.type.toString()),
            },
            series: consumptionData.map((data) => (Math.round(data.consumption * 1000)) / 1000),
        });

        setTotalConsumption(consumptionData.reduce((sum, data) => sum + data.consumption, 0));
    }, [consumptionData]);

    useEffect(() => {
        setBarChartConfig((prevConfig) => ({
            ...prevConfig,
            series: [{data: errors.map((data) => data.count)}],
            options: {
                ...prevConfig.options,
                xaxis: {
                    ...prevConfig.options.xaxis,
                    categories: errors.map((data) => data.type.toString()),
                },
            },
        }));
    }, [errors]);

    function fetchData() {
        fetchPieChartData()
        fetchBarChartData()
    }

    useEffect(() => {
        fetchData()
    }, [selectedLocation]);

    function LocationPicker() {
        return (
            <div>
                <label htmlFor="underline_select" className="sr-only">
                    Underline select
                </label>
                <select
                    id="underline_select"
                    className="bg-blue-900 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-blue-900 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    value={selectedLocation}
                    onChange={handleLocationChange}
                >
                    {locations.map((location) => (
                        <option key={location.value} value={location.value}>
                            {location.label}
                        </option>
                    ))}
                </select>
            </div>
        )
    }

    function DatePicker(value, onChangeFunction, onBlurFunction) {
        return (
            <div className="relative max-w-full">
                <div className="absolute inset-y-0 start-0 flex items-center ps-3.5 pointer-events-none">
                    <svg className="w-4 h-4 text-gray-500 dark:text-gray-400" aria-hidden="true"
                         xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                        <path
                            d="M20 4a2 2 0 0 0-2-2h-2V1a1 1 0 0 0-2 0v1h-3V1a1 1 0 0 0-2 0v1H6V1a1 1 0 0 0-2 0v1H2a2 2 0 0 0-2 2v2h20V4ZM0 18a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V8H0v10Zm5-8h10a1 1 0 0 1 0 2H5a1 1 0 0 1 0-2Z"/>
                    </svg>
                </div>
                <input
                    type="date"
                    className="bg-blue-900 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full ps-10 p-2.5 dark:bg-blue-900 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="Select date"
                    value={value}
                    onChange={onChangeFunction}
                    onBlur={onBlurFunction}
                />
            </div>
        )
    }

    function handleFromDateChange(selected) {
        setSelectedFromDate(selected.target.value)
    }

    function handleToDateChange(selected) {
        setSelectedToDate(selected.target.value)
    }

    function getAverageConsumption() {
        const fromDate = new Date(selectedFromDate);
        const toDate = new Date(selectedToDate);

        const diffMilli = Math.abs(toDate - fromDate) + 1; // From X to X is still one day = 24h
        const diffDays = Math.ceil(diffMilli / (1000 * 60 * 60 * 24))
        return totalConsumption / diffDays;
    }

    function getCO2Emissions() {
        return 0.3987 * totalConsumption;
    }

    function roundToNDecimals(number, decimals) {
        const roundNumber = 10 ** decimals;
        return Math.round(number * roundNumber) / roundNumber;
    }

    function getGeneratedElectricity() {
        return 0;
    }

    return (
        <Card>
            <CardHeader
                floated={false}
                shadow={false}
                color="transparent"
                className="flex flex-col gap-4 rounded-none md:flex-row md:items-center"
            >
                <div>
                    <Typography variant="h6" color="blue-gray">
                        Energy Board - Overview
                    </Typography>
                </div>

            </CardHeader>
            <CardBody className="mt-4 grid grid-cols-1 md:grid-cols-3 gap-4 px-2">
                <div className="col-span-1">
                    {LocationPicker()}
                </div>
                <div className="col-span-1">
                    {DatePicker(selectedFromDate, handleFromDateChange, () => fetchData())}
                </div>
                <div className="col-span-1">
                    {DatePicker(selectedToDate, handleToDateChange, () => fetchData())}
                </div>

                <div className="col-span-3">
                    <div className="flex">
                        <div className="flex flex-col mr-20">
                            <div className="font-bold text-lg">Total:</div>
                            <div className="text-lg">{roundToNDecimals(totalConsumption, 3)} kWh</div>
                        </div>
                        <div className="flex flex-col mr-20">
                            <div className="font-bold text-lg">Durchschnitt:</div>
                            <div className="text-lg">{roundToNDecimals(getAverageConsumption(), 3)} kWh/Tag</div>
                        </div>
                        <div className="flex flex-col mr-20">
                            <div className="font-bold text-lg">CO2 Emissionen:</div>
                            <div className="text-lg">{roundToNDecimals(getCO2Emissions(), 3)} kg</div>
                        </div>
                        <div className="flex flex-col">
                            <div className="font-bold text-lg">Stromerzeugung:</div>
                            <div className="text-lg">{roundToNDecimals(getGeneratedElectricity(), 3)} kWh</div>
                        </div>
                    </div>
                </div>

                <div className="col-span-3 flex">
                    <div className="flex-grow">
                        <h2 className="font-bold text-lg">Verbrauch</h2>
                        <Chart {...pieChartConfig} />
                    </div>
                    <div className="flex-grow">
                        <h2 className="font-bold text-lg">Fehler</h2>
                        <Chart {...barChartConfig} />
                    </div>
                </div>
            </CardBody>
        </Card>
    );
}