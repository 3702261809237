import * as React from 'react';
import {FormEvent, SyntheticEvent} from 'react';
import './LoginContainer.css';
import {Form, Grid, Image, InputOnChangeData, Message, Container, Segment, Flag, Header} from 'semantic-ui-react';
import {RouteComponentProps} from 'react-router';
import i18n from "../../../../locale/i18n";
import authService from "../../services/AuthService";
import {appInfo} from '../../services/AppInfo';
import {withRouter} from "react-router-dom";

const logo = require('../../logo.png');
const translator = i18n.t.bind(i18n);


const sustainLogo = require('../../../../img/CAS_SustainData_Logo-transparent-1.png');

type LoginProps = {
    submitting: boolean,
    loading: boolean,
    userId: string,
    password: string,
    error: boolean,
    errorMsg: string
}

class LoginContainer extends React.Component<RouteComponentProps<{}>, LoginProps> {
    constructor(props: RouteComponentProps<{}>) {
        super(props);

        this.state = {
            submitting: false,
            loading: false,
            userId: '',
            password: '',
            error: false,
            errorMsg: ''
        };

        this.onFormSubmit = this.onFormSubmit.bind(this);
        this.onChangeUser = this.onChangeUser.bind(this);
        this.onChangePassword = this.onChangePassword.bind(this);
        this.onChangeLanguage = this.onChangeLanguage.bind(this);
        this.redirectToDefaultPage = this.redirectToDefaultPage.bind(this);
    }

    redirectToDefaultPage = () => {
        window.location.reload();
        //this.props.history.push('/');
    };


    componentDidMount () {
        authService.checkIfLoggedIn().then(this.redirectToDefaultPage).catch(
            function() {
            }

        );
    }

    onChangeLanguage(language : string) {
        i18n.changeLanguage(language);
        this.forceUpdate();
    }

    onFormSubmit( event: FormEvent<HTMLElement>) {
        event.preventDefault( );
        if (this.state.submitting || this.state.loading) {
            return;
        }
        this.setState({
            submitting: true
        });
        authService.login({
            userId: this.state.userId,
            password: this.state.password
        })
            .then(this.redirectToDefaultPage)
            .catch(error => {
                this.setState({
                    submitting: false,
                    password: '',
                    error: true,
                    errorMsg: error
                });
            });
    }

    onChangeUser(event: SyntheticEvent<HTMLInputElement>, data: InputOnChangeData) {
        if (this.state.submitting) {
            return;
        }
        this.setState({ userId: data.value });
    }

    onChangePassword(event: SyntheticEvent<HTMLInputElement>, data: InputOnChangeData) {
        if (this.state.submitting) {
            return;
        }
        this.setState({ password: data.value });
    }

    render() {
        return <div className="login-page">
            <div className="login-page-visual-container">
                <div className="login-page-visual-logo-container">
                    <Image src={sustainLogo} />
                </div>
            </div>
            <div className="login-page-form-container">
                <Form className="login-page-form" loading={this.state.submitting} onSubmit={this.onFormSubmit} error={this.state.error}>
                    <Header textAlign="center" as='h1'>Anmeldung</Header>
                    <Segment>
                        <Message
                          error={true}
                          icon="warning"
                          header={"Login fehlgeschlagen"}
                          content={translator(this.state.errorMsg)}
                        />
                        {/* when userId is email, add hint to browser for mobile users (text input causes space after dot when typing!) */}
                        <Form.Input
                          label={"Benutzername oder E-Mail"}
                          icon="user"
                          name="userId"
                          type="string"
                          required={true}
                          value={this.state.userId}
                          onChange={this.onChangeUser}
                        />
                        <Form.Input
                          label={"Passwort"}
                          icon="key"
                          name="password"
                          type="password"
                          required={true}
                          value={this.state.password}
                          onChange={this.onChangePassword}
                        />
                        <Form.Button fluid primary type="submit" content={"Login"}/>
                    </Segment>
                </Form>
            </div>
        </div>
    }
}

export default withRouter(LoginContainer);
