import React, {FC, useCallback, useEffect, useMemo, useRef, useState} from "react";

import GenericDataManagementService from "../../services/data-management/GenericDataManagementService";
import {withRouter} from "../../utils/compat";
import {RouteComponentProps} from "react-router";
import {AxiosResponse} from "axios";
import _set from 'lodash/set';

import {SortDirection} from "react-virtualized";
import TransactionType from "../../types/transaction-type";
import {Button, Checkbox, Dropdown, Form as SForm, Input, TextArea} from "semantic-ui-react";
import Source from "../../types/source";
import {DateInput} from "semantic-ui-calendar-react";

import Form from '@rjsf/core';
import {
    SemanticUiArrayFieldTemplate,
    SemanticUiFieldTemplate,
    SemanticUiObjectFieldTemplate
} from "../../components/json-schema-form";
import {toast} from "react-toastify";
import TransactionReferenceDataSelectionModal from "./TransactionReferenceDataSelectionModal";
import TransactionReferenceData from "../../types/transaction-reference-data";

interface RouteParams {
}

interface TransactionAddProps extends RouteComponentProps<RouteParams> {

}

interface TransactionFormData {
    type?: string;
    source?: string;
    amount?: number;
    fields?: Record<string, any>;
    date?: string;
    dateEnd?: string;
    notes?: string;
}

const emptyTransaction: TransactionFormData = {};

export const TransactionAdd: FC<TransactionAddProps> = withRouter((props: TransactionAddProps) => {

    const dataManagementService = new GenericDataManagementService();

    const [transactionTypes, setTransactionTypes] = useState<Array<TransactionType>>([]);
    const [sources, setSources] = useState<Array<Source>>([]);

    const [hasEndDate, setHasEndDate] = useState<boolean>(false);

    const [transaction, setTransaction] = useState<TransactionFormData>(emptyTransaction);
    const [type, setType] = useState<string>();
    const [source, setSource] = useState<string>();
    const [amount, setAmount] = useState<number>();
    const [fields, setFields] = useState<Record<string, any>>();
    const [date, setDate] = useState<string>();
    const [dateEnd, setDateEnd] = useState<string>();
    const [notes, setNotes] = useState<string>();

    const [submitting, setSubmitting] = useState<boolean>(false);

    const [selectReferenceDataModalOpen, setSelectReferenceDataModalOpen] = useState<boolean>(false);

    const headerRef = useRef<HTMLHeadingElement>(null);

    const openSelectReferenceDataModal = () => {
        setSelectReferenceDataModalOpen(true);
    };

    const closeSelectReferenceDataModal = () => {
        setSelectReferenceDataModalOpen(false);
    };

    const applyReferenceData = (t: TransactionReferenceData) => {
        const newTransaction = {...emptyTransaction};

        newTransaction.type = t.type;
        newTransaction.source = t.source;
        newTransaction.amount = t.amount;
        newTransaction.fields = t.fields;
        newTransaction.notes = t.notes;

        const ensureLeadingZero = (monthOrDayPart: string | number) => {
            if (typeof monthOrDayPart === 'number' && monthOrDayPart < 10) {
                return '0' + monthOrDayPart;
            }
            if (('' + monthOrDayPart).length < 2) {
                return '0' + monthOrDayPart;
            }
            return '' + monthOrDayPart;
        }

        let startDayString: string | undefined;
        let endDayString: string | undefined;
        if (t.date && t.date.year && t.date.month && t.date.day) {
            startDayString = `${t.date.year}-${ensureLeadingZero(t.date.month)}-${ensureLeadingZero(t.date.day)}`;
        }
        if (t.dateEnd && t.dateEnd.year && t.dateEnd.month && t.dateEnd.day) {
            endDayString = `${t.dateEnd.year}-${ensureLeadingZero(t.dateEnd.month)}-${ensureLeadingZero(t.dateEnd.day)}`;
        }

        newTransaction.date = startDayString;
        if (endDayString && endDayString !== startDayString) {
            setHasEndDate(true);
            newTransaction.dateEnd = endDayString;
        }

        setTransaction(newTransaction);
        closeSelectReferenceDataModal();
    };

    const endDateIsBeforeDate = (() => {
        if (!hasEndDate) {
            return false;
        }
        if (!transaction.date || !transaction.dateEnd) {
            return false;
        }
        return new Date(transaction.dateEnd) < new Date(transaction.date);
    })();

    const formValid = (() => {
        if (endDateIsBeforeDate) {
            return false;
        }
        if (hasEndDate && !transaction.dateEnd) {
            return false;
        }
        return transaction.source && transaction.type && transaction.amount && transaction.date;
    })();

    const submitForm = useCallback(() => {
        if (!formValid || submitting) {
            return;
        }

        setSubmitting(true);
        dataManagementService.createOrUpdateDocument("transaction/" + transaction.type, transaction)
          .then(() => {
              toast.success('Transaktion gespeichert!', {});
              setHasEndDate(false);
              setTransaction({...emptyTransaction});

              headerRef.current?.scrollIntoView();
          }).catch((err) => {
              console.error('Error saving transaction', err);
              toast.error('Transaktion konnte nicht gespeichert werden.');
          }).finally(() => {
            setSubmitting(false);
          });
    }, [transaction, formValid, submitting, headerRef.current]);

    const fieldSchema = useMemo(() => {

        let transactionType = transactionTypes.filter((transactionType) => transactionType.name === transaction.type)[0];
        if (transactionType) {
            return transactionType.fieldsSchema;
        }

        return "{}";
    }, [transactionTypes, transaction.type]);


    const updateField = useCallback((key: string, value: any) => {
        // let t = {...transaction};
        // _set(t, key, value);

        if (key === "type") {
            setType(value);
            setFields({});
            // _set(t, "fields", {});
        } else if (key === "source") {
            setSource(value);
        } else if (key === "amount") {
            setAmount(value);
        } else if (key === "fields") {
            setFields(value);
        } else if (key === "date") {
            setDate(value);
        } else if (key === "dateEnd") {
            setDateEnd(value);
        } else if (key === "notes") {
            setNotes(value);
        }
        // setTransaction(t);
    }, [transaction, setTransaction]);

    useEffect(() => {
        setTransaction({
           type: type,
           source: source,
           amount: amount,
           fields: fields,
           date: date,
           dateEnd: dateEnd,
           notes: notes
        });
    }, [type, source, amount, fields, date, dateEnd, notes])

    useEffect(() => {
        dataManagementService.listDocuments("transaction-type", 0, "description", SortDirection.ASC).then(
            (response: AxiosResponse<any>) => {

                setTransactionTypes(response.data.content);
            }
        );
    }, [setTransactionTypes]);

    useEffect(() => {
        dataManagementService.listDocuments("source", 0, "name", SortDirection.ASC).then(
            (response: AxiosResponse<any>) => {

                setSources(response.data.content);
            }
        );
    }, [setSources]);


    return (

        <>
            <h1 ref={headerRef}>Transaktion erfassen</h1>
            <div style={{marginBottom: '2em'}}>
                <Button primary type="button" onClick={openSelectReferenceDataModal}>
                    Referenzdaten übernehmen...
                </Button>
            </div>
            <SForm onSubmit={submitForm}>
                <SForm.Field>
                    <label>Quelle</label>
                    <Dropdown
                        placeholder='Auswählen...'
                        fluid
                        value={transaction.source || ""}
                        onChange={(event: any, data: any) => updateField("source", data.value)}
                        selection
                        options={sources.map((source)=> { return {key: source.name, text: source.name, value: source.name}})}
                    />
                </SForm.Field>
                <SForm.Field>
                    <label>Transaktionstyp</label>
                    <Dropdown
                        placeholder='Auswählen...'
                        fluid
                        onChange={(event: any, data: any) => updateField("type", data.value)}
                        value={transaction.type || ""}
                        selection
                        options={transactionTypes.map((transactionType)=> { return {key: transactionType.name, text: transactionType.description, value: transactionType.name}})}
                    />
                    <label></label>
                </SForm.Field>
                <SForm.Field>
                    <label>Menge</label>
                    <Input type={"number"} value={transaction.amount || ""} onChange={(event: any, data: any) => updateField("amount", data.value)}></Input>
                </SForm.Field>
            </SForm>

            <Form
                className={'ui form json-form'}
                liveValidate={true}
                FieldTemplate={SemanticUiFieldTemplate}
                schema={JSON.parse(fieldSchema)}
                formData={transaction.fields}
                ObjectFieldTemplate={SemanticUiObjectFieldTemplate}
                ArrayFieldTemplate={SemanticUiArrayFieldTemplate}
                showErrorList={false}
                onChange={(data) => updateField("fields", data.formData)}
            ></Form>
            <SForm onSubmit={submitForm}>
                <SForm.Field>
                    <label>Datum</label>
                    <DateInput
                        name="datesRange"
                        placeholder="Von"
                        dateFormat="YYYY-MM-DD"
                        value={transaction.date || ''}
                        onChange={(_ev: any, { value } : any) => updateField("date", value)}
                        iconPosition="left"
                    ></DateInput>
                </SForm.Field>
                <SForm.Field>
                    <label>Bezieht sich der Beleg auf einen bekannten Zeitraum?</label>
                    <Checkbox
                      checked={hasEndDate}
                      onChange={(event: any, data: any) => setHasEndDate(data.checked)}
                      type={"checkbox"}
                    ></Checkbox>
                </SForm.Field>
                <SForm.Field>
                    <label>End-Datum</label>
                    <DateInput
                        name="datesRange"
                        placeholder="Bis"
                        dateFormat="YYYY-MM-DD"
                        value={transaction.dateEnd || ''}
                        minDate={transaction.date && !endDateIsBeforeDate ? transaction.date : undefined}
                        onChange={(_ev: any, { value } : any) => updateField("dateEnd", value)}
                        iconPosition="left"
                        disabled={!hasEndDate}
                    ></DateInput>
                    {endDateIsBeforeDate && <span style={{color: '#CE0000'}}>
                        Das End-Datum muss nach dem Start-Datum liegen.
                    </span>}
                </SForm.Field>

                <SForm.Field>
                    <label>Notizen</label>
                    <TextArea
                      value={transaction.notes || ''}
                      onChange={(_ev, { value }) => updateField("notes", value)}
                    />
                </SForm.Field>
                <SForm.Button primary disabled={!formValid} loading={submitting} type="submit">
                    Speichern
                </SForm.Button>
            </SForm>

            <TransactionReferenceDataSelectionModal
                open={selectReferenceDataModalOpen}
                onCancel={closeSelectReferenceDataModal}
                onSelect={applyReferenceData}
            />
        </>
    );

});
