import AppRoute from './AppRoute';
import authService from './services/AuthService';
import {ManagementSources} from "./views/management/ManagementSources";
import {ManagementKPIType} from "./views/management/ManagementKPIType";
import {ManagementReports} from "./views/management/ManagementReports";
import {Transaction} from "./views/transaction/Transaction";
import {CompensationProject} from "./views/compenstion/CompensationProject";
import {CompensationPlanting} from "./views/compenstion/CompensationPlanting";
import {ManagementCalculationLogic} from "./views/management/ManagementCalculationLogic";
import {ManagementInputType} from "./views/management/ManagementInputType";
import {ManagementTransactionType} from "./views/management/ManagementTransactionType";
import {ManagementMetadataType} from "./views/management/ManagementMetadataType";
import {ManagementOrganizations} from './views/management/ManagementOrganization';
import {ManagementUsers} from "./views/management/ManagementUser";
import {TransactionTypes} from "./views/transaction/TransactionTypes";
import {TransactionAdd} from "./views/transaction/TransactionAdd";
import {Maintenance} from "./myClimate-demo-case/Maintenance";
import {CreateCCF} from "./myClimate-demo-case/CreateCCF";
import {EditFactor} from "./myClimate-demo-case/EditFactor";
import {ManagementRole} from "./views/management/ManagementRole";
import {CreatePCF} from './myClimate-demo-case/CreatePCF';
import {Home} from "./views/Home";
import {SubRouteNavPage} from "./views/SubRouteNavPage";
import ErrorList from "./components/ErrorList";
import EnergyGraph from "./components/EnergyGraph";
import ErrorPie from "./components/ErrorPie";


export const GUARD_ADMIN = () => {
  return authService.loggedIn && authService.principal && authService.principal.type === 'admin';
};

export const GUARD_LOGGEDIN = () => {
  return authService.loggedIn;
};

export const GUARD_GUEST = () => {
  return !authService.loggedIn;
};

export const GUARD_TRANSACTION_TYPE = (transactionType: string) => {

    let userRoles = authService.roles.filter(role => authService.principal.roles.includes(role.name));

    let transactionTypeFound = false;

    userRoles.forEach(role => {
        if (role.categoryPermissions?.includes(transactionType)) {
            transactionTypeFound = true;
        }
    })

    return transactionTypeFound;
}

const GUARD_USER_MANAGEMENT = GUARD_ADMIN();

const routes: AppRoute[] = [
    {path: '/', component: Home, title: 'Home', hideInNav: false, exact: true, subRoutes: []},
    // {path: '/dashboard', component: DashboardCompany, title: 'DashboardCompany', icon: 'chart line', hideInNav: true, exact: true, subRoutes: []},
    // {path: '/product', component: DashboardProduct, title: 'DashboardProducts', icon: 'chart pie', hideInNav: true, exact: true, subRoutes: []},
    // {path: '/scope1', component: DashboardScope1, title: 'DashboardScope1', icon: 'chart pie', hideInNav: true, exact: true, subRoutes: []},
    // {path: '/scope2', component: DashboardScope2, title: 'DashboardScope1', icon: 'chart pie', hideInNav: true, exact: true, subRoutes: []},
    // {path: '/scope3', component: DashboardScope3, title: 'DashboardScope1', icon: 'chart pie', hideInNav: true, exact: true, subRoutes: []},
    {path: '/pocList', component: ErrorList, title: 'ErrorList', hideInNav: false, exact: true, subRoutes: []},
    {path: '/pocGraph', component: EnergyGraph, title: 'Energy Consumption', icon: 'chart pie', hideInNav: false, exact: true, subRoutes: []},
    {path: '/pocPie', component: ErrorPie, title: 'Energy Overview', icon: 'chart pie', hideInNav: false, exact: true, subRoutes: []},
    // {path: '/pocBar', component: ErrorBar, title: 'ErrorBar', icon: 'chart pie', hideInNav: false, exact: true, subRoutes: []},

  // {path: '/data-entry', component: SubRouteNavPage, title: 'DataEntry', exact: true,
  //   subRoutes: [
  //     {path: '/cf-cockpit', component: Maintenance, title: 'CFCockpit', icon: 'cogs', subRoutes: [], guard: GUARD_ADMIN, hideInNav: false},
  //     {path: '/transaction-add/', component: TransactionAdd, title: 'TransactionAdd', icon: "plus", hideInNav: false, exact: true, subRoutes: []},
  //   ]},
  // {path: '/reporting', component: SubRouteNavPage, title: 'Reporting', exact: true,
  //   subRoutes: [
  //     {path: '/dashboard', component: DashboardCompany, title: 'DashboardCompany', icon: 'chart line', hideInNav: false, exact: true, subRoutes: []},
  //     {path: '/product', component: DashboardProduct, title: 'DashboardProducts', icon: 'chart pie', hideInNav: false, exact: true, subRoutes: []},
  //     {path: '/compensate/project', component: CompensationProject, title: 'CompensateProject', icon: 'leaf', hideInNav: false, exact: true, subRoutes: []},
  //     {path: '/transaction/', component: TransactionTypes, title: 'Transaction', icon: "chain", hideInNav: false, exact: true, subRoutes: []},
  //   ]},
  // {path: '/master-data', component: SubRouteNavPage, title: 'MasterData', exact: true,
  //   subRoutes: [
  //     {path: '/management/sources', component: ManagementSources, title: 'ManagementSources', icon: 'sitemap', subRoutes: [], guard: GUARD_ADMIN},
  //   ]},
  {path: '/settings', component: SubRouteNavPage, title: 'Settings', hideInNav: false, exact: true, subRoutes: [
      // {path: '/management/calculationLogics', component: ManagementCalculationLogic, title: 'ManagementCalculationLogics', icon: 'calculator', subRoutes: [], guard: GUARD_ADMIN},
      // {path: '/management/metadata-types', component: ManagementMetadataType, title: 'ManagementMetadataTypes', icon: 'database', subRoutes: [], guard: GUARD_ADMIN},
      //
      // {path: '/management/input-types', component: ManagementInputType, title: 'ManagementInputTypes', icon: 'arrow alternate circle right', subRoutes: [], guard: GUARD_ADMIN},
      // {path: '/management/transaction-types', component: ManagementTransactionType, title: 'ManagementTransactionTypes', icon: 'chain', subRoutes: [], guard: GUARD_ADMIN},
      // {path: '/management/kpi-types', component: ManagementKPIType, title: 'ManagementKPITypes', icon: 'chart line', subRoutes: [], guard: GUARD_ADMIN},
      //
      // {path: '/administration/organizations', component: ManagementOrganizations, title: 'ManagementOrganizations', icon: 'building', hideInNav: false, exact: true, subRoutes: []},
      {path: '/management/users', component: ManagementUsers, title: 'ManagementUsers', icon: 'user', subRoutes: [], guard: GUARD_ADMIN, hideInNav: false},
      {path: '/management/roles', component: ManagementRole, title: 'ManagementRoles', icon: 'user', subRoutes: [], guard: GUARD_ADMIN, hideInNav: false},
    ], guard:GUARD_ADMIN},

    {path: '/cf-cockpit', component: Maintenance, title: 'CFCockpit', icon: 'cogs', subRoutes: [], guard: GUARD_ADMIN, hideInNav: true},

    {path: '/transaction/', component: TransactionTypes, title: 'Transaction', icon: "chain", hideInNav: true, exact: true, subRoutes: [
        {path: '/transaction-add/', component: TransactionAdd, title: 'TransactionAdd', icon: "plus", hideInNav: false, exact: true, subRoutes: []},
    ]},
    {path: '/transaction/:transactionType', component: Transaction, title: 'database', icon: "arrow alternate circle right", hideInNav: true, exact: true, subRoutes: []},
    {path: '/transaction-add/', component: TransactionAdd, title: 'TransactionAdd', icon: "plus", hideInNav: true, exact: true, subRoutes: []},

    {path: '/compensate/project', component: CompensationProject, title: 'CompensateProject', icon: 'leaf', hideInNav: true, exact: true, subRoutes: []},
    {path: '/compensate/planting', component: CompensationPlanting, title: 'CompensatePlanting', icon: 'leaf', hideInNav: true, exact: true, subRoutes: []},

    {path: '/management/calculationLogics', component: ManagementCalculationLogic, title: 'ManagementCalculationLogics', icon: 'calculator', subRoutes: [], guard: GUARD_ADMIN, hideInNav: true},
    {path: '/management/input-types', component: ManagementInputType, title: 'ManagementInputTypes', icon: 'industry', subRoutes: [], guard: GUARD_ADMIN, hideInNav: true},
    {path: '/management/transaction-types', component: ManagementTransactionType, title: 'ManagementTransactionTypes', icon: 'industry', subRoutes: [], guard: GUARD_ADMIN, hideInNav: true},
    {path: '/management/metadata-types', component: ManagementMetadataType, title: 'ManagementMetadataTypes', icon: 'industry', subRoutes: [], guard: GUARD_ADMIN, hideInNav: true},
    {path: '/management/kpi-types', component: ManagementKPIType, title: 'ManagementKPITypes', icon: 'industry', subRoutes: [], guard: GUARD_ADMIN, hideInNav: true},
    {path: '/management/sources', component: ManagementSources, title: 'ManagementSources', icon: 'sitemap', subRoutes: [], guard: GUARD_ADMIN, hideInNav: true},
    {path: '/management/reports', component: ManagementReports, title: 'ManagementReports', icon: 'sticky note', subRoutes: [], guard: GUARD_ADMIN, hideInNav: true},
    {path: '/management/users', component: ManagementUsers, title: 'ManagementUsers', icon: 'user', subRoutes: [], guard: GUARD_ADMIN, hideInNav: true},
    {path: '/management/roles', component: ManagementRole, title: 'ManagementRoles', icon: 'user', subRoutes: [], guard: GUARD_ADMIN, hideInNav: true},

    {path: '/management/calculationLogics', component: ManagementCalculationLogic, title: 'ManagementCalculationLogics', icon: 'calculator', subRoutes: [], guard: GUARD_LOGGEDIN, hideInNav: true},
    {path: '/management/input-types', component: ManagementInputType, title: 'ManagementInputTypes', icon: 'industry', subRoutes: [], guard: GUARD_LOGGEDIN, hideInNav: true},
    {path: '/management/transaction-types', component: ManagementTransactionType, title: 'ManagementTransactionTypes', icon: 'industry', subRoutes: [], guard: GUARD_LOGGEDIN, hideInNav: true},
    {path: '/management/metadata-types', component: ManagementMetadataType, title: 'ManagementMetadataTypes', icon: 'industry', subRoutes: [], guard: GUARD_LOGGEDIN, hideInNav: true},
    {path: '/management/kpi-types', component: ManagementKPIType, title: 'ManagementKPITypes', icon: 'industry', subRoutes: [], guard: GUARD_LOGGEDIN, hideInNav: true},
    {path: '/management/sources', component: ManagementSources, title: 'ManagementSources', icon: 'sitemap', subRoutes: [], guard: GUARD_LOGGEDIN, hideInNav: true},
    {path: '/management/reports', component: ManagementReports, title: 'ManagementReports', icon: 'sticky note', subRoutes: [], guard: GUARD_LOGGEDIN, hideInNav: true},
    {path: '/management/users', component: ManagementUsers, title: 'ManagementUsers', icon: 'user', subRoutes: [], guard: GUARD_LOGGEDIN, hideInNav: true},

    {path: '/maintenance', component: Maintenance, title: 'Maintenance', icon: 'cogs', exact: true, subRoutes: [], guard: GUARD_LOGGEDIN, hideInNav: true},
    {path: '/maintenance/createCCF', component: CreateCCF, title: 'CreateCCF', icon: 'add', subRoutes: [], guard: GUARD_LOGGEDIN, hideInNav: true},
    {path: '/maintenance/createPCF', component: CreatePCF, title: 'CreatePCF', icon: 'add', subRoutes: [], guard: GUARD_LOGGEDIN, hideInNav: true},
    {path: '/maintenance/editFactor', component: EditFactor, title: 'EditFactor', icon: 'pencil', subRoutes: [], guard: GUARD_LOGGEDIN, hideInNav: true},

    {path: '/administration/organizations', component: ManagementOrganizations, title: 'ManagementOrganizations', icon: 'building', hideInNav: true, exact: true, subRoutes: []},


];

export default routes;
