import React, {useCallback, useEffect} from "react";
import { useDocumentListPageState } from "../../services/listManagement";
import {
  Button,
  Dimmer,
  Grid,
  Header,
  Icon,
  Loader,
  Pagination,
  Table,
  TableCell,
  TableRow,
} from "semantic-ui-react";
import { IconButton } from "../../components/IconButton";
import { CreateEditModal, CreateEditModalFormProps } from "./CreateEditModal";
import { DeleteModal } from "./DeleteModal";
import _get from "lodash/get";
import { format } from "date-fns";
import { GenericDataManagement } from "../../services/data-management/DataManagementType";
import { NavLink } from "react-router-dom";
import ChooseCalculation from "../../myClimate-demo-case/ChooseCalculation";

// TODO: should we use an interface for all props?
export interface TableColumn {
  title: string;
  field: string;
  sortable?: boolean;
  format?: string;
  cellRenderer?: (item: Record<string, any>, value: any) => React.ReactNode;
}

export const SimpleCrudPage = (props: {
  entityType: string;
  dataManagementService: GenericDataManagement;

  singularName: string;
  pluralName: string;
  modalHeader?: string;
  description?: string;

  defaultSortField?: string;
  defaultSortDirection?: "ASC" | "DESC";

  modalSize?: "mini" | "tiny" | "small" | "large" | "fullscreen" | undefined;

  formComponent?: React.FC<CreateEditModalFormProps>;
  tableRowComponent?: React.FC<{ item: Record<string, any> }>;

  readOnly?: boolean;

  tableColumns?: Array<TableColumn>;
  headerName?: string,
  withoutHeader?: boolean,
  disableAdd?: boolean

  simulateLoading?: boolean;
  addChooseCalculation?: boolean
}) => {
  // Destructure necessary props
  const { entityType, defaultSortField, defaultSortDirection, headerName, withoutHeader, disableAdd, addChooseCalculation } = props;

  // Initialize document list page state using custom hook
  const listPage = useDocumentListPageState(
    props.dataManagementService,
    entityType,
    defaultSortField || "",
    defaultSortDirection || "ASC"
  );

  // Initialize local states for managing CRUD operations
  const [editEntity, setEditEntity] = React.useState<any | null>(null);
  const [createEditModalOpen, setCreateEditModalOpen] = React.useState(false);

  // Handler functions for opening and closing the create/edit modal
  const openCreateEditModal = (entity: any | null) => {
    setEditEntity(entity);
    setCreateEditModalOpen(true);
  };

  const closeCreateEditModal = () => {
    setCreateEditModalOpen(false);
  };

  // Handler for when a create or edit operation is saved
  const onCreateEditModalSaved = useCallback(() => {
    setCreateEditModalOpen(false);
    listPage.loadCurrentPage().then(() => {});
  }, [listPage]);

  // Initialize local state for deleting an entity
  const [deleteEntity, setDeleteEntity] = React.useState<any | null>(null);
  const [deleteModalOpen, setDeleteModalOpen] = React.useState(false);

  // Handler functions for opening and closing the delete modal
  const openDeleteModal = (entity: any | null) => {
    setDeleteEntity(entity);
    setDeleteModalOpen(true);
  };

  const closeDeleteModal = () => {
    setDeleteModalOpen(false);
  };

  // Handler for when a delete operation is confirmed
  const onDeleteModalSaved = useCallback(() => {
    setDeleteModalOpen(false);
    listPage.loadCurrentPage().then(() => {});
  }, [listPage]);

  // Function to get and format the cell value based on the column's configuration
  const getCellValue = (column: TableColumn, item: Record<string, any>) => {
    const value = _get(item, column.field);

    if (column.cellRenderer) {
      return column.cellRenderer(item, value);
    }

    if (column.format === "datetime") {
      if (!value) {
        return "";
      }
      try {
        return format(new Date(value), "dd.MM.yyyy HH:mm:ss");
      } catch (e) {
        return value;
      }
    }
    if (column.format === "date") {
      if (!value) {
        return "";
      }
      try {
        return format(new Date(value), "dd.MM.yyyy");
      } catch (e) {
        return value;
      }
    }
    if (column.format?.startsWith("navlink:")) {
      if (!value) {
        return "";
      }
      try {
        const path = column.format?.replace("navlink:", "");
        const varIndex = path.indexOf(":");
        const endVarIndex = path.lastIndexOf("/", varIndex);

        const pathVar: string = path.substring(
          varIndex + 1,
          endVarIndex > varIndex + 1 ? endVarIndex : path.length
        );

        return (
          <NavLink to={path.replace(":" + pathVar, _get(item, pathVar))}>
            {value}
          </NavLink>
        );
      } catch (e) {
        return value;
      }
    }

    return value;
  };

  return (
    <div>
      {/*{*/}
      {/*  withoutHeader ? <></>*/}
      {/*  : <Header as="h1">{props.pluralName}</Header>*/}
      {/*}*/}

      {/*{*/}
      {/*  disableAdd ? <></>*/}
      {/*      : */}
      <Grid verticalAlign={"middle"}>
        <Grid.Row>
          <Grid.Column width="8">
            {
              withoutHeader ? <></>
              : <Header as="h1">{headerName ? headerName : props.pluralName}</Header>
            }
            { props.description ? <p>{props.description}</p> : null }
          </Grid.Column>
          <Grid.Column width="8" textAlign="right">
            {
              addChooseCalculation ?
                  <ChooseCalculation dataManagementService={props.dataManagementService} />
                  : <></>
            }
            {(!props.readOnly && !disableAdd) ? (
                <Button primary onClick={() => openCreateEditModal(null)}>
                  <Icon name="plus" /> {headerName ? headerName : props.singularName} erstellen
                </Button>
            ) : <></>}
          </Grid.Column>
        </Grid.Row>
      </Grid>
      {/*}*/}
      <Table>
        <Table.Header>
          <Table.Row>
            {props.tableColumns?.map((column, index) => (
              <Table.HeaderCell key={index}>{column.title}</Table.HeaderCell>
            ))}
            {!props.tableRowComponent && (
              <Table.HeaderCell textAlign="right">Aktionen</Table.HeaderCell>
            )}
          </Table.Row>
        </Table.Header>
        <Table.Body style={{ position: "relative" }}>
          {/* TODO no div in tbody */}
          {listPage.items?.map((item: any) => {
            if (props.tableRowComponent) {
              return <props.tableRowComponent key={item.name} item={item} />;
            }

            return (
              <TableRow key={item.name}>
                {props.tableColumns?.map((column, index) => (
                  <TableCell key={index}>
                    {getCellValue(column, item)}
                  </TableCell>
                ))}
                <TableCell textAlign="right">
                  {props.readOnly && (
                      <Button onClick={() => openCreateEditModal(item)} icon={"eye"} className={"read"} />
                    // <IconButton
                    //   primary
                    //   icon="eye"
                    //   onClick={() => openCreateEditModal(item)}
                    // />
                  )}
                  {!props.readOnly && (
                      <Button onClick={() => openCreateEditModal(item)} icon={"edit"} className={"edit"} />
                    // <IconButton
                    //   primary
                    //   icon="edit"
                    //   onClick={() => openCreateEditModal(item)}
                    // />
                  )}
                  {!props.readOnly && (
                      <Button onClick={() => openDeleteModal(item)} icon={"trash"} className={"delete"} />
                    // <IconButton
                    //   primary
                    //   icon="trash"
                    //   onClick={() => openDeleteModal(item)}
                    // />
                  )}
                </TableCell>
              </TableRow>
            );
          })}
        </Table.Body>
      </Table>
      {listPage.loading && (
        <Dimmer active inverted>
          <Loader inverted />
        </Dimmer>
      )}{" "}
      <Pagination
        totalPages={listPage.totalPages}
        activePage={listPage.page + 1}
        onPageChange={(e, data) =>
          listPage.changePage(+(data.activePage || 1) - 1)
        }
      />
      <CreateEditModal
        simulateLoading={props.simulateLoading}
        entityType={props.entityType}
        size={props.modalSize || "large"}
        open={createEditModalOpen}
        modalHeader={props.modalHeader}
        onClose={closeCreateEditModal}
        onSaved={onCreateEditModalSaved}
        editEntity={editEntity ? editEntity : undefined}
        formComponent={props.formComponent}
        readOnly={props.readOnly}
        dataManagementService={props.dataManagementService}
      />
      <DeleteModal
        entityType={props.entityType}
        deleteEntity={deleteEntity ? deleteEntity : undefined}
        open={deleteModalOpen}
        onClose={closeDeleteModal}
        onDeleted={onDeleteModalSaved}
        dataManagementService={props.dataManagementService}
      />
    </div>
  );
};
