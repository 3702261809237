import React, {useEffect, useState} from "react";
import {Card, CardBody, CardHeader, Typography,} from "@material-tailwind/react";
import Chart from "react-apexcharts";
import RestClient from "../../../lib/RestClient";

const initialChartConfig = {
    type: "line",
    height: 400,
    series: [
        {
            name: "Placeholder1",
            data: [
                {x: new Date("2024-02-02T12:00:00").toISOString(), y: 10},
                {x: new Date("2024-02-02T13:00:00").toISOString(), y: 20},
                // Add more data points as needed
            ],
        },
        {
            name: "Placeholder2",
            data: [
                {x: new Date("2024-02-02T12:30:00").toISOString(), y: 15},
                {x: new Date("2024-02-02T13:30:00").toISOString(), y: 25},
                // Add more data points as needed
            ],
        },
    ],
    options: {
        chart: {
            toolbar: {
                show: false,
            },
        },
        title: {
            show: "",
        },
        dataLabels: {
            enabled: false,
        },
        colors: ["#001f78", "#00b5db", "#f03d14", "#fa6e0a", "#ffbf45"],
        stroke: {
            lineCap: "round",
            curve: "smooth",
        },
        markers: {
            size: 0,
        },
        xaxis: {
            type: "datetime",
            tooltip: {
                enabled: false, // Disable the x-axis tooltip
            },
        },
        yaxis: {
            labels: {
                style: {
                    colors: "#616161",
                    fontSize: "12px",
                    fontFamily: "inherit",
                    fontWeight: 400,
                },
            },
        },
        grid: {
            show: true,
            borderColor: "#dddddd",
            strokeDashArray: 5,
            xaxis: {
                lines: {
                    show: true,
                },
            },
            padding: {
                top: 5,
                right: 20,
            },
        },
        fill: {
            opacity: 0.8,
        },
        tooltip: {
            theme: "dark",
            x: {
                format: "dd.MM.yyyy HH:mm", // Customize the date and time format if needed
            },
        },
    },
};


export default function EnergyGraph() {
    const [locations, setLocations] = useState([
        {value: "DE002068", label: "DE002068"},
        {value: "DE032065", label: "DE032065"}
    ]);
    const [selectedLocation, setSelectedLocation] = useState("DE032065")

    const [selectedFromDate, setSelectedFromDate] = useState("2023-10-19")
    const [selectedToDate, setSelectedToDate] = useState("2023-10-20")
    const [lineChartConfig, setLineChartConfig] = useState(initialChartConfig)

    const [consumptionData, setConsumptionData] = useState([])

    function handleFromDateChange(selected) {
        setSelectedFromDate(selected.target.value)
    }

    function handleToDateChange(selected) {
        setSelectedToDate(selected.target.value)
    }

    function handleLocationChange(event) {
        setSelectedLocation(event.target.value)
    }

    useEffect(() => {
        setLineChartConfig((prevConfig) => ({
            ...prevConfig,
            series: consumptionData.map((entry) => ({
                name: entry.name,
                data: entry.data.map((dateValue) => ({
                    x: dateValue.x,
                    y: dateValue.y,
                })),
            }))
        }));
    }, [consumptionData]);

    function LocationPicker() {
        return (
            <div>
                <label htmlFor="underline_select" className="sr-only">
                    Underline select
                </label>
                <select
                    id="underline_select"
                    className="bg-blue-900 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-blue-900 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    value={selectedLocation}
                    onChange={handleLocationChange}
                >
                    {locations.map((location) => (
                        <option key={location.value} value={location.value}>
                            {location.label}
                        </option>
                    ))}
                </select>
            </div>
        )
    }

    function DatePicker(value, onChangeFunction, onBlurFunction) {
        return (
            <div className="relative max-w-full">
                <div className="absolute inset-y-0 start-0 flex items-center ps-3.5 pointer-events-none">
                    <svg className="w-4 h-4 text-gray-500 dark:text-gray-400" aria-hidden="true"
                         xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                        <path
                            d="M20 4a2 2 0 0 0-2-2h-2V1a1 1 0 0 0-2 0v1h-3V1a1 1 0 0 0-2 0v1H6V1a1 1 0 0 0-2 0v1H2a2 2 0 0 0-2 2v2h20V4ZM0 18a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V8H0v10Zm5-8h10a1 1 0 0 1 0 2H5a1 1 0 0 1 0-2Z"/>
                    </svg>
                </div>
                <input
                    type="date"
                    className="bg-blue-900 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full ps-10 p-2.5 dark:bg-blue-900 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="Select date"
                    value={value}
                    onChange={onChangeFunction}
                    onBlur={onBlurFunction}
                />
            </div>
        )
    }

    function fetchLineGraphData() {
        const seriesMap = {};

        RestClient.get("temp/consumptionByDate/" + selectedLocation + "?dateFrom=" + selectedFromDate + "&dateTo=" + selectedToDate)
            .then(response => {
                response.data.forEach(({date, type, value}) => {
                    const dateObj = new Date(date);
                    // Hacky date conversion
                    dateObj.setHours(dateObj.getHours() + 2);
                    const timestamp = dateObj.getTime();

                    if (!seriesMap[type]) {
                        seriesMap[type] = [];
                    }

                    seriesMap[type].push({x: timestamp, y: value});
                });

                // Convert the map to the final series array
                const series = Object.keys(seriesMap).map((type) => ({
                    name: type,
                    data: seriesMap[type],
                }));
                setConsumptionData(series)
                }
            )
    }

    function fetchData() {
        fetchLineGraphData()
    }

    useEffect(() => {
        fetchData()
    }, [selectedLocation]);

    return (
        <Card>
            <CardHeader
                floated={false}
                shadow={false}
                color="transparent"
                className="flex flex-col gap-4 rounded-none md:flex-row md:items-center"
            >
                <div>
                    <Typography variant="h6" color="blue-gray">
                        Energy Consumption
                    </Typography>
                </div>
            </CardHeader>
            <CardBody className="mt-4 grid grid-cols-1 md:grid-cols-3 gap-4 px-2">
                <div className="col-span-1">
                    {LocationPicker()}
                </div>
                <div className="col-span-1">
                    {DatePicker(selectedFromDate, handleFromDateChange, () => fetchData())}
                </div>
                <div className="col-span-1">
                    {DatePicker(selectedToDate, handleToDateChange, () => fetchData())}
                </div>

                <div className="col-span-3 flex">
                    <div className="flex-grow">
                        <Chart {...lineChartConfig} />
                    </div>
                </div>
            </CardBody>
        </Card>
    );
}