import React, {FC, useCallback} from 'react';
import {Grid, Header, Icon, Image, Segment} from "semantic-ui-react";
import routes from "../routes";
import i18n from "../../../locale/i18n";
import {RouteComponentProps} from "react-router";
import {withRouter} from "../utils/compat";

import './Home.css';
import {Link, NavLink} from "react-router-dom";

const sustainLogo = require('../../../img/CAS_SustainData_Logo-transparent-1.png');

const t = i18n.t.bind(i18n);

interface ManagementProps extends RouteComponentProps {

}

export const Home: FC<ManagementProps> = withRouter((props: ManagementProps) => {

  const navigate = useCallback((path) => {
    props.history.push(path);
  }, []);

  return (
    <div>
      <div className="home-logo-banner">
        <div className="home-logo-wrapper">
          <Image src={sustainLogo} className="home-logo" />
        </div>
      </div>

      {routes.filter(r => r.subRoutes && r.subRoutes.length && !r.hideInNav)
        .map(route => <React.Fragment key={route.path}>
          <Header as='h2' className="home-nav-category-header">
            {t('Route.' + route.title)}
          </Header>

          <Grid>
            {route.subRoutes?.map((subRoute) =>
              (<Grid.Column width={2}>
                <NavLink to={subRoute.path || '/404'}>
                  <Segment textAlign={"center"}>
                    <Header icon style={{color: 'rgba(0,0,0,0.8)', cursor: "pointer"}}>
                      <Icon name={subRoute.icon}></Icon>
                      <p>{t("Route." + subRoute.title || "")}</p>
                    </Header>
                  </Segment>
                </NavLink>
              </Grid.Column>)
            )}
          </Grid>
        </React.Fragment>)
      }

    </div>
  );
});