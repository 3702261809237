import * as React from 'react';
import {SyntheticEvent} from 'react';
import {Icon, Menu, MenuItemProps, MenuProps, Responsive, Sidebar, SidebarProps} from 'semantic-ui-react';
import {NavLink} from 'react-router-dom';
import AppRoute from '../../AppRoute';
import authService, {AuthPrincipal} from '../../services/AuthService';
import i18n from "../../../../locale/i18n";

import './MenuSidebar.css';
import AppContainer from '../../containers/AppContainer/AppContainer';

const t = i18n.t.bind(i18n);

interface IMenuSidebarProps {
    onLogout: Function;
    currentUser: AuthPrincipal;
    routes: AppRoute[];
    currentPath: string;
}

interface IMenuSidebarState {
    visible: boolean;
}

// Menu should auto hide on start and after selecting an entry when width is below certain treshold
const shouldAutoHide = () => window.innerWidth < 1024;

class MenuSidebar extends React.Component<IMenuSidebarProps, IMenuSidebarState> {
    constructor(props: IMenuSidebarProps) {
        super(props);

        this.state = {
            visible: !shouldAutoHide()
        };

        this.logoutClickHandler = this.logoutClickHandler.bind(this);
    }

    public logoutClickHandler(ev: SyntheticEvent<HTMLAnchorElement>, data: MenuItemProps): void {
        this.props.onLogout();
    }

    public toggle() {
        this.setState(
            {
                visible: !this.state.visible
            }
        );
    }

    public onChangeLanguage(language : string) {
        i18n.changeLanguage(language);
        AppContainer.instance.forceUpdate();
    }

    public onNavigated() {
        if(shouldAutoHide()) {
            this.setState({visible: false});
        }
    }

    public render() {
        const menuProps: MenuProps = {
            vertical: true,
        };

        const sidebarProps: {} | SidebarProps = {
            as: Menu,
            direction: 'left',
            visible: this.state.visible,
            ...menuProps
        };

        const menuItemProps: {} | MenuItemProps = {
            link: false,
            as: NavLink
        };


        const sidebarContent = this.props.routes
                                .filter(route => !route.hideInNav)
                                .filter(route => !route.guard || route.guard())
                                .map((route, routeIdx) =>
            (!route.requiredRole) && [
                <Menu.Item {...menuItemProps} to={route.path} exact={!!route.exact} onClick={() => route.subRoutes && route.subRoutes.length > 0 ? false : this.onNavigated()} key={route.path + routeIdx}>
                    <Icon name={route.icon}/>
                    {t(route.title)}
                </Menu.Item>
                ,
                ...(route.subRoutes
                    ? [(<Menu.Menu key={route.path + routeIdx + '_submenu'}>
                        {route.subRoutes.filter(sR => !sR.guard || sR.guard()).map((subRoute, idx) =>
                            (!subRoute.requiredRole && (
                                <Menu.Item
                                    className={((subRoute.heading === true) && "subRouteHeading") || ((subRoute.heading === false) && "subRouteItem") || ""} {...menuItemProps}
                                    exact to={subRoute.path}
                                    onClick={() => this.onNavigated()}
                                    key={route.path + routeIdx + '_submenu' + idx}
                                >
                                    <Icon name={subRoute.icon}/>
                                    <div>{((subRoute.heading === true) ? "" : "> ") + t("Route." + subRoute.title)}</div>
                                </Menu.Item>)))}
                    </Menu.Menu>)]
                    : [])
            ]
        );

        return (
            <>
                <Sidebar className="menu-sidebar" {...sidebarProps} >
                    {sidebarContent}

                    <Responsive {...Responsive.onlyMobile}>
                        {authService.loggedIn ? (
                            <>
                                <Menu.Item className={"menu-sidebar-mobile-last"} onClick={() => this.props.onLogout()}>
                                    <Icon name="sign out" />
                                    {"Logout"}
                                </Menu.Item>
                            </>
                        ) : (
                            <Menu.Item className={"menu-sidebar-mobile-user"} onClick={() => authService.redirectToLoginForm()}>
                                <Icon name="sign in"/>
                                {t("Auth.Login")}
                            </Menu.Item>
                        )}


                    </Responsive>
                </Sidebar>
            </>
        );
    }
}

export default MenuSidebar;
