import React, {FC, useCallback} from 'react';
import {Grid, Header, Icon, Image, Segment} from "semantic-ui-react";
import routes from "../routes";
import i18n from "../../../locale/i18n";
import {RouteComponentProps} from "react-router";
import {withRouter} from "../utils/compat";

const t = i18n.t.bind(i18n);

interface SubRouteNavPageProps extends RouteComponentProps {

}

export const SubRouteNavPage: FC<SubRouteNavPageProps> = withRouter((props: SubRouteNavPageProps) => {
    const navigate = useCallback((path) => {
        props.history.push(path);
    }, []);

    return (
        <div>
            <Header as='h1'>
              {t(
                "Route." + (routes.filter((r)=> r.path === props.location.pathname)?.pop()?.title ?? '')
              )}
            </Header>

            <Grid>
            {routes.filter((r)=> r.path === props.location.pathname)?.pop()?.subRoutes?.map((subRoute) =>
                (<Grid.Column width={2}>
                    <a onClick={navigate.bind(null, subRoute.path)}>
                        <Segment textAlign={"center"}>
                            <Header icon style={{color: "rgba(0,0,0,0.8)", cursor:"pointer"}}>
                                <Icon name={subRoute.icon}></Icon>
                                <p>{t("Route." +subRoute.title || "")}</p>
                            </Header>
                    </Segment>
                    </a>
                </Grid.Column>)
            )}
            </Grid>

        </div>
    );
});