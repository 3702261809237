import React, {useEffect, useLayoutEffect, useRef, useState} from "react";
import RestClient from "../../../lib/RestClient";

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function ErrorList() {
    const checkbox = useRef()
    const [checked, setChecked] = useState(false)
    const [indeterminate, setIndeterminate] = useState(false)
    const [selectedError, setSelectedError] = useState([])
    const [errors, setErrors] = useState([])
    const [filteredErrors, setFilteredErrors] = useState([])
    const [filterValues,setFilterValues] = useState({
        location: "",
        riskValue: "",
        category: "",
        description: "",
        status: ""
    })


    useLayoutEffect(() => {
        const isIndeterminate = selectedError.length > 0 && selectedError.length < errors.length
        setChecked(selectedError.length === errors.length)
        setIndeterminate(isIndeterminate)
        checkbox.current.indeterminate = isIndeterminate
    }, [selectedError])

    function toggleAll() {
        setSelectedError(checked || indeterminate ? [] : errors)
        setChecked(!checked && !indeterminate)
        setIndeterminate(false)
    }

    function getData() {
        function getSubject(error) {
            if (!error) {
                return "";
            }
            if (error.errorType === "data-gap") {
                return "Datenlücke";
            }
            if (error.errorType === "no-values-24h") {
                return "Keine Daten in den letzten 24 Stunden";
            }
            if (error.errorType === "average-deviation") {
                return "Abweichung vom Mittelwert";
            }
            if (error.errorType === "interpretation-failed") {
                return "Interpretation fehlgeschlagen";
            }
            return "Anderer Fehler";
        }

        function getSeverity(errors) {
            if (!errors) {
                return "";
            }
            const error = errors[0];
            if (error.errorType === "data-gap") {
                return "Mittel";
            }
            if (error.errorType === "no-values-24h") {
                return "Hoch";
            }
            if (error.errorType === "average-deviation") {
                return "Niedrig";
            }
            if (error.errorType === "interpretation-failed") {
                return "Niedrig";
            }
            return "";
        }

        function getStatusText(status) {
            if(status === 0){
                return "Neu";
            }
            if(status === 1){
                return "In Bearbeitung";
            }
            if(status === 2){
                return "Korrigiert";
            }
            return "";
        }

        RestClient.get("temp/storeErrors")
            .then(response => {
                const errors = response.data.flatMap(item => (
                    item.data.errors.map(error => ({
                            id: Math.random().toString(36).substr(2, 9) + '-' + Date.now().toString(36),
                            standort: item.data.storeID,
                            schweregrad: getSeverity(item.data.errors),
                            kategorie: 'Allgemein',
                            zeitpunkt: item.data.calculationDate + " " + item.data.calculationTime,
                            betreff: getSubject(error),
                            status: getStatusText(error.status)
                        }
                    ))
                ));

                setErrors(errors);
            })
    }

    useEffect(() => {
        getData()
    }, []);

    useEffect(() => {
        console.log(filterValues);
        console.log("Before: " + filteredErrors.length);
        const newFilteredErrors = errors.filter(error => (
            (filterValues.location === "" || error.standort.toLowerCase().includes(filterValues.location.toLowerCase())) &&
            (filterValues.riskValue === "" || error.schweregrad.toLowerCase().includes(filterValues.riskValue.toLowerCase())) &&
            (filterValues.category === "" || error.kategorie.toLowerCase().includes(filterValues.category.toLowerCase())) &&
            (filterValues.description === "" || error.betreff.toLowerCase().includes(filterValues.description.toLowerCase())) &&
            (filterValues.status === "" || error.status.toLowerCase().includes(filterValues.status.toLowerCase()))
        ));
        setFilteredErrors(newFilteredErrors);
    }, [filterValues, errors]);

    useEffect(() => {
        console.log("After: " + filteredErrors.length);
    }, [filteredErrors]);

    function removeSelected() {
        setErrors(
            errors.filter(error =>
                !selectedError.some(selectedError => {
                    const key1 = error.id;
                    const key2 = error.id;
                    return key1 === key2;
                })
            ));
        setSelectedError([]);
    }

    return (
        <div className="px-4 sm:px-6 lg:px-8">
            <div className="sm:flex sm:items-center">
                <div className="sm:flex-auto">
                    <h1 className="text-base font-semibold leading-6 text-gray-900">Fehler</h1>
                    <p className="mt-2 text-sm text-gray-700">
                        Liste der Fehler nach Ort, Schweregrad, Kategorie, Betreff, Zeitpunkt und Status.
                    </p>
                </div>
            </div>
            <div className="sm:flex sm:items-center mt-4">
                <input
                    type="text"
                    placeholder="Filter Standort"
                    value={filterValues.location}
                    onChange={e => setFilterValues({ ...filterValues, location: e.target.value })}
                    className="py-2 px-3 border border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:w-auto"
                />
                <input
                    type="text"
                    placeholder="Filter Schweregrad"
                    value={filterValues.riskValue}
                    onChange={e => setFilterValues({ ...filterValues, riskValue: e.target.value })}
                    className="py-2 px-3 border border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:w-auto mt-2 sm:mt-0 sm:ml-2"
                />
                <input
                    type="text"
                    placeholder="Filter Kategorie"
                    value={filterValues.category}
                    onChange={e => setFilterValues({ ...filterValues, category: e.target.value })}
                    className="py-2 px-3 border border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:w-auto mt-2 sm:mt-0 sm:ml-2"
                />
                <input
                    type="text"
                    placeholder="Filter Betreff"
                    value={filterValues.description}
                    onChange={e => setFilterValues({ ...filterValues, description: e.target.value })}
                    className="py-2 px-3 border border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:w-auto mt-2 sm:mt-0 sm:ml-2"
                />
                <input
                    type="text"
                    placeholder="Filter Status"
                    value={filterValues.status}
                    onChange={e => setFilterValues({ ...filterValues, status: e.target.value })}
                    className="py-2 px-3 border border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:w-auto mt-2 sm:mt-0 sm:ml-2"
                />
            </div>
            <div className="mt-8 flow-root">
                <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                        <div className="relative">
                            {selectedError.length > 0 && (
                                <div
                                    className="absolute left-14 top-0 flex h-12 items-center space-x-3 bg-white sm:left-12">
                                    <button
                                        type="button"
                                        className="inline-flex items-center rounded bg-white px-2 py-1 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 disabled:cursor-not-allowed disabled:opacity-30 disabled:hover:bg-white"
                                    >
                                        Bearbeiten
                                    </button>
                                    <button
                                        type="button"
                                        className="inline-flex items-center rounded bg-white px-2 py-1 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 disabled:cursor-not-allowed disabled:opacity-30 disabled:hover:bg-white"
                                        onClick={() => removeSelected()}
                                    >
                                        Erledigt
                                    </button>
                                </div>
                            )}
                            <table className="min-w-full table-fixed divide-y divide-gray-300">
                                <thead>
                                <tr>
                                    <th scope="col" className="relative px-7 sm:w-12 sm:px-6">
                                        <input
                                            type="checkbox"
                                            className="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                            ref={checkbox}
                                            checked={checked}
                                            onChange={toggleAll}
                                        />
                                    </th>
                                    <th scope="col"
                                        className="min-w-[12rem] py-3.5 pr-3 text-left text-sm font-semibold text-gray-900">
                                        Standort
                                    </th>
                                    <th scope="col"
                                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                        Schweregrad
                                    </th>
                                    <th scope="col"
                                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                        Kategorie
                                    </th>
                                    <th scope="col"
                                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                        Betreff
                                    </th>
                                    <th scope="col"
                                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                        Zeitpunkt
                                    </th>
                                    <th scope="col"
                                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                        Status
                                    </th>
                                </tr>
                                </thead>
                                <tbody className="divide-y divide-gray-200 bg-white">
                                {filteredErrors.map((error) => (
                                    <tr key={error.id}
                                        className={selectedError.includes(error) ? 'bg-gray-50' : undefined}>
                                        <td className="relative px-7 sm:w-12 sm:px-6">
                                            {selectedError.includes(error) && (
                                                <div className="absolute inset-y-0 left-0 w-0.5 bg-indigo-600"/>
                                            )}
                                            <input
                                                type="checkbox"
                                                className="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                                value={error.zeitpunkt}
                                                checked={selectedError.includes(error)}
                                                onChange={(e) =>
                                                    setSelectedError(
                                                        e.target.checked
                                                            ? [...selectedError, error]
                                                            : selectedError.filter((p) => p !== error)
                                                    )
                                                }
                                            />
                                        </td>
                                        <td
                                            className={classNames(
                                                'whitespace-nowrap py-4 pr-3 text-sm font-medium',
                                                selectedError.includes(error) ? 'text-indigo-600' : 'text-gray-900'
                                            )}
                                        >
                                            {error.standort}
                                        </td>
                                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{error.schweregrad}</td>
                                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{error.kategorie}</td>
                                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{error.betreff}</td>
                                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{error.zeitpunkt}</td>
                                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{error.status}</td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}